import React, { Component } from 'react';
import axios from 'axios';
import SpinnerLoader from '../../components/common/SpinnerLoader';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import Header from "../../components/admin/common/Header";
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import store from '../../store';
import arrow_back from './../../assets/icons/arrow-back.svg'
import emptyImg from './../../assets/images/emptyimage.png';
import { Link } from 'react-router-dom/cjs/react-router-dom';

class ManageBrands extends Component {
    constructor(props) {
        super(props);
        this.authInfo = store.getState().auth.authInfo;
        this.cloudName = process.env.REACT_APP_CLOUD_NAME;
        this.apiKey = process.env.REACT_APP_CLOUD_API_KEY;
        this.apiSecret = process.env.REACT_APP_CLOUD_API_SECRET;
        this.state = {
            isEditMode: false,
            brandId: null,
            brandName: "",
            logoImage: "",
            imagePreview: null,
            emptyImg: emptyImg,
            data: ""
        };

        //for close bootstrap modal
        // this.modalRef = React.createRef();

        // Define columns
        this.brand_column = [
            {
                name: 'Brand Logo',
                selector: (row, i) => (
                    <img
                        src={row.logoImage}
                        alt="Not selected"
                        style={{ width: "150px", height: "150px" }}
                    />
                ),
                sortable: true
            },
            {
                name: 'Brand Name',
                selector: (row, i) => row.brandName,
                sortable: true
            },
            // {
            //     name: 'Created Date',
            //     selector: (row, i) => new Date(row.createdAt).toLocaleDateString(),
            //     sortable: true
            // },
            // {
            //     name: 'Status',
            //     selector: (row, i) => (
            //         <span className={`badge ${row.isActive ? 'bg-success' : 'bg-danger'}`}>
            //             {row.isActive === true ? 'Active' : 'In-Active'}
            //         </span>
            //     ),
            //     sortable: true
            // },

            {
                name: "Action",
                cell: (row, i) => {
                    return (
                        <>
                            <button
                                className="custom_btn btn_yellow_bordered w-auto btn btn-width action_btn_new"
                                data-bs-whatever="@mdo"
                                onClick={() => this.handleEdit(row)}
                            >
                                Edit
                            </button>
                            {/* {row.isActive ? (
                                <button
                                    className="custom_btn btn_yellow_bordered w-auto btn btn-width action_btn_new"
                                    onClick={() => this.handleChangeStatus(row, false)}
                                >
                                    In-Active
                                </button>
                            ) : (
                                <button
                                    className="custom_btn btn_yellow_bordered w-auto btn btn-width action_btn_new"
                                    onClick={() => this.handleChangeStatus(row, true)}
                                >
                                    Active
                                </button>
                            )}
                            <button
                                className="custom_btn btn_yellow_bordered w-auto btn btn-width action_btn_new"
                                onClick={() => this.handleDelete(row)}
                            >
                                Delete
                            </button> */}
                        </>
                    );
                },
            }

        ];
    }

    componentDidMount() {
        this.fetchBrandList();
    }

    fetchBrandList = async () => {
        this.setState({ loading: true });
        try {
            const url = "/admin/brands";
            const response = await axios.get(url, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': `Bearer ${this.authInfo.token}`
                }
            });
            this.setState({ data: response.data.data, loading: false });
        } catch (error) {
            console.error("There was an error fetching service list category data", error);
            this.setState({ loading: false });
        }
    };

    //Cloudinary code for upload image
    handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file.size <= 5242880) {
            this.setState({ imageFile: file });
            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState({ imagePreview: reader.result });
            };
            reader.readAsDataURL(file);
        } else {
            this.setState({ imageFile: null, image: emptyImg });
            toast.error("Image size must be less than 5 MB", { autoClose: 3000 });
        }
    };

    handleSubmit = async (values, { resetForm }) => {
        const uploadImage = () => {
            return new Promise((resolve, reject) => {
                const data = new FormData();
                data.append("file", this.state.imageFile);
                data.append("upload_preset", "pay-earth-images");
                data.append("cloud_name", this.cloudName);

                fetch(`https://api.cloudinary.com/v1_1/${this.cloudName}/image/upload`, {
                    method: "post",
                    body: data
                })
                    .then((res) => res.json())
                    .then((data) => resolve(data))
                    .catch((err) => reject(err));
            });
        };

        try {
            let imageData;
            if (this.state.imageFile) {
                imageData = await uploadImage();
                console.log("imageData", imageData)
            } else {
                imageData = { secure_url: emptyImg, public_id: '' };
            }

            const brandData = {
                brandName: values.brandName,
                logoImage: imageData.secure_url,
                isPopular: true,
                isActive: true,
                createdBy: this.authInfo.id
            };
            resetForm();
            await axios.post('/admin/brands', brandData, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': `Bearer ${this.authInfo.token}`
                }

            }).then(async (response) => {
                if (response.data.status === false) {
                    toast.error(response.data.message);
                } else {
                    toast.success(response.data.message);
                    // Re-fetch the list to include the new category
                    // await this.fetchServiceCategoryList();
                    resetForm();
                    this.setState({ imagePreview: null, imageFile: null });
                }
            })
        } catch (error) {
            console.error("There was an error saving the service category", error);
        } finally {
            // setSubmitting(false);
        }
    }

    handleRowSelected = (state) => {
        this.setState({ selectedRows: state.selectedRows });
    };


    handleEdit = (row) => {
        this.setState({
            isEditMode: true,
            brandId: row.id,
            imagePreview: row.logoImage,
            brandName: row.brandName
        });

    }

    // updateServiceCategory = async (values, { setSubmitting }) => {
    //     console.log("values", values)
    //     try {
    //         const updateCategoryUrl = `/admin/categories/${this.state.currentCategory.id}`;
    //         const authInfo = JSON.parse(localStorage.getItem('authInfo'));
    //         const token = authInfo ? authInfo.token : '';

    //         await axios.patch(updateCategoryUrl, { categoryName: values.editCategoryName }, {
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json;charset=UTF-8',
    //                 'Authorization': `Bearer ${token}`
    //             }
    //         }).then((response) => {
    //             console.log("Checking response", response)
    //             if (response.data.status === true) {
    //                 toast.success(response.data.message)
    //                 // Re-fetch the list to include the updated category
    //                 this.fetchServiceCategoryList();
    //                 this.setState({ currentCategory: null });
    //                 this.modalRef.current.click();  // Close the modal
    //             } else {
    //                 toast.error(response.data.message);
    //             }
    //         })
    //     } catch (error) {
    //         console.error('There was an error updating the service category', error);
    //     } finally {
    //         setSubmitting(false);
    //     }
    // };

    handleDelete = async (row) => {
        try {
            const deleteCategoryUrl = `/admin/categories/${row.id}`;
            const authInfo = JSON.parse(localStorage.getItem("authInfo"));
            const token = authInfo ? authInfo.token : "";

            await axios.delete(deleteCategoryUrl, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                }
            });

            // Re-fetch the list to reflect changes
            // this.fetchServiceCategoryList();
        } catch (error) {
            console.error("There was an error deleting the service category", error);
        }
    }

    //On working
    handleChangeStatus = async (row, isActive) => {
        console.log("Check isActiveisActive value", isActive)
        try {
            const updateStatusUrl = `/admin/categories/status/${row.id}`;
            const authInfo = JSON.parse(localStorage.getItem("authInfo"));
            const token = authInfo ? authInfo.token : "";

            await axios.put(updateStatusUrl, { isActive }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                }
            });

            // Re-fetch the list to reflect changes
            // this.fetchServiceCategoryList();
        } catch (error) {
            console.error("There was an error changing the status", error);
        }
    }

    render() {
        const { data, selectedRows, currentCategory,

            isEditMode, brandName, imagePreview,

        } = this.state;

        console.log("isEditMode", isEditMode)
        console.log("brandName", brandName)

        // Yup validation for Formik form
        const validationSchema = Yup.object({
            brandName: Yup.string().required('Brand name is required'),
            logoImage: Yup.mixed()
                .required('Logo image is required')
                .test('fileSize', 'File size is too large', value => value && value.size <= 350 * 350)
                .test('fileFormat', 'Unsupported Format', value => value && ['image/jpeg', 'image/png'].includes(value.type))
        });

        return (
            <React.Fragment>
                <div className="seller_dash_wrap pb-5">
                    <div className="container">
                        <Header />
                        <div className="inr_top_page_title">
                            <h2>Manage Brands</h2>
                        </div>
                        <Helmet>
                            <title>{"Brands - Pay Earth"}</title>
                        </Helmet>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="createpost bg-white rounded-3 mt-4 addPost_left_container">
                                    <div className="cp_top">
                                        <div className="cumm_title">
                                            ADD NEW BRANDS
                                        </div>
                                    </div>

                                    <div className="cp_body">
                                        <Formik
                                            initialValues={{
                                                brandName: brandName || "",
                                                logoImage: "",
                                            }}
                                            enableReinitialize={true}
                                            validationSchema={validationSchema}
                                            onSubmit={this.handleSubmit}
                                        >
                                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm }) => (
                                                <form onSubmit={handleSubmit} encType="multipart/form-data">
                                                    <div className="mb-4">
                                                        <label htmlFor="brandName" className="form-label">
                                                            Enter Brand Name <small className="text-danger">*</small>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="brandName"
                                                            name="brandName"
                                                            onBlur={handleBlur}
                                                            value={values.brandName}
                                                            onChange={handleChange}
                                                            placeholder="Brand Name"
                                                        />
                                                        {touched.brandName && errors.brandName ? (
                                                            <small className="text-danger">{errors.brandName}</small>
                                                        ) : null}
                                                    </div>

                                                    <div className="formImage-wrapper">
                                                        <label className="form-label">Logo Image</label>
                                                        <div className="text-center formImage-pannel">
                                                            {!this.state.imagePreview ? (
                                                                <div className="formImage">
                                                                    <img src={this.state.emptyImg} alt="..." />
                                                                    <p className="text-danger"> Size must be less than 5 MB</p>
                                                                </div>
                                                            ) : (
                                                                <div className="formImage">
                                                                    <img src={this.state.imagePreview} alt="..." />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="formImageInput">
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="logoImage"
                                                            accept="image/*"
                                                            onChange={(event) => {
                                                                setFieldValue("logoImage", event.currentTarget.files[0]);
                                                                this.handleImageChange(event);
                                                            }}
                                                            onBlur={handleBlur}
                                                        />
                                                    </div>

                                                    <div className="cre_ser_pay col-md-12 pt-4 pb-4">
                                                        <button type="submit" className="btn custom_btn btn_yellow w-auto">
                                                        {isEditMode ? "Update Brand" : "Create Brand"}
                                                        </button>
                                                    </div>
                                                </form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="createpost bg-white rounded-3 mt-4 addPost_left_container">
                                    <div className="cp_top">
                                        <div className="cumm_title">Brand Lists</div>
                                    </div>
                                    <div className="cp_body">
                                        <DataTableExtensions
                                            columns={this.brand_column}
                                            data={data}
                                        >
                                            <DataTable
                                                pagination
                                                noHeader
                                                highlightOnHover
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                            // selectableRows
                                            // selectedRows={selectedRows}
                                            // onSelectedRowsChange={this.handleRowSelected}
                                            />
                                        </DataTableExtensions>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                

            </React.Fragment >
        );
    }
}

export default ManageBrands;
